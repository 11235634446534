<template>

	<!-- Layout Header ( Navbar ) -->
	<a-layout-header>
		<div class="header-col header-brand">
			<h6>{{ app_name }}</h6>

			<!-- Trigger Button For Navigation Menu For Small Screens -->
			<a-button type="link" @click="collapseNav = collapseNav ? 0 : 1 " class="btn-menu-trigger">
				<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"/></svg>
			</a-button>
			<!-- Trigger Button For Navigation Menu For Small Screens -->

		</div>
		<div class="header-col header-nav">

			<!-- Navigation Menu For Large Screens -->
			<a-menu mode="horizontal" class="menu-large">
				<a-menu-item>
					<router-link v-if="isAuthenticated && auth_type === 'admin' && !isCurrentRoute('organisations')" :to="{
							    name: 'organisations'
								}">
									<span class="icon">
										<a-icon type="home" class="m-0" />
									</span>
					</router-link>
				</a-menu-item>
				<a-menu-item v-if="isAuthenticated && hasSelectedOrganisation && !isCurrentRoute('organisationHome')">
					<router-link :to="{
							    name: 'organisationHome'
								}">
									<span class="icon">
										<a-icon type="shop" theme="filled" class="m-0" />
									</span>
					</router-link>
				</a-menu-item>
				<a-sub-menu style="padding: 0;"  v-if="isAuthenticated">
								<span slot="title">
									<span class="icon">
										<a-icon type="user" class="m-0" />
									</span>
								</span>
					<a-menu-item-group>
						<a-menu-item v-if="isEmployee">
							<a-button danger type="link" icon="user" @click.prevent="goToProfile">
								<span>Profile</span>
							</a-button>
						</a-menu-item>
						<a-menu-item>
							<a-button danger type="link" icon="logout" @click.prevent="logout">
								<span>Logout</span>
							</a-button>
						</a-menu-item>

					</a-menu-item-group>
				</a-sub-menu>

			</a-menu>
			<!-- / Navigation Menu For Large Screens -->

			<!-- Collapsible Navigation Menu For Small Screens -->
			<div class="menu-small">
				
				<!-- Collapsible Component For Navigation Menu For Small Screens -->
				<a-collapse v-model="collapseNav" accordion>
					<a-collapse-panel key="1">

						
						<a-menu mode="inline"
							:open-keys="openKeys"
							@openChange="onOpenChange"
						>
							<a-menu-item>
								<router-link v-if="isAuthenticated && !isCurrentRoute('organisations')" :to="{
							    name: 'organisations'
								}">
									<span class="icon">
										<a-icon type="home" class="m-0" />
										Home
									</span>
								</router-link>
							</a-menu-item>
							<a-menu-item v-if="hasSelectedOrganisation && !isCurrentRoute('organisationHome')">
								<router-link :to="{
							    name: 'organisationHome'
								}">
									<span class="icon">
										<a-icon type="shop" theme="filled" class="m-0" />
										{{ selected_organisation.name }}
									</span>
								</router-link>
							</a-menu-item>
							<a-sub-menu key="dashboards" style="padding: 0;">
								<span slot="title">
									<span class="icon">
										<a-icon type="dashboard" theme="filled" class="m-0" />
									</span>
									<span class="label">Dashboards</span>
								</span>
								<a-menu-item-group>
									<a-menu-item>
										<router-link to="/dashboards/">
											<span class="label">Default</span>
										</router-link>
									</a-menu-item>
									<a-menu-item>
										<router-link to="/dashboards/crm">
											<span class="label">CRM</span>
										</router-link>
									</a-menu-item>
								</a-menu-item-group>
							</a-sub-menu>
							<a-menu-item class="menu-item-header">
								Pages
							</a-menu-item>
							<a-sub-menu key="pages" style="padding: 0;">
								<span slot="title">
									<span class="icon">
										<a-icon type="code" theme="filled" class="m-0" />
									</span>
									<span class="label">Pages</span>
								</span>
								<a-menu-item-group>
									<a-sub-menu key="profile" style="padding: 0;" title="Profile">
										<a-menu-item-group>
											<a-menu-item>
												<router-link to="/pages/profile/profile-overview">
													<span class="label">Profile Overview</span>
												</router-link>
											</a-menu-item>
											<a-menu-item>
												<router-link to="/pages/profile/all-projects">
													<span class="label">All Projects</span>
												</router-link>
											</a-menu-item>
										</a-menu-item-group>
									</a-sub-menu>
									<a-sub-menu key="users" style="padding: 0;" title="Users">
										<a-menu-item-group>
											<a-menu-item>
												<router-link to="/pages/users/new-user">
													<span class="label">New User</span>
												</router-link>
											</a-menu-item>
										</a-menu-item-group>
									</a-sub-menu>
									<a-sub-menu key="account" style="padding: 0;" title="Account">
										<a-menu-item-group>
											<a-menu-item>
												<router-link to="/pages/account/settings">
													<span class="label">Settings</span>
												</router-link>
											</a-menu-item>
											<a-menu-item>
												<router-link to="/pages/account/billing">
													<span class="label">Billing</span>
												</router-link>
											</a-menu-item>
											<a-menu-item>
												<router-link to="/pages/account/invoice">
													<span class="label">Invoice</span>
												</router-link>
											</a-menu-item>
										</a-menu-item-group>
									</a-sub-menu>
									<a-sub-menu key="projects" style="padding: 0;" title="Projects">
										<a-menu-item-group>
											<a-menu-item>
												<router-link to="/pages/projects/timeline">
													<span class="label">Timeline</span>
												</router-link>
											</a-menu-item>
										</a-menu-item-group>
									</a-sub-menu>
									<a-menu-item>
										<router-link to="/pages/pricing">
											<span class="label">Pricing</span>
										</router-link>
									</a-menu-item>
									<a-menu-item>
										<router-link to="/pages/rtl">
											<span class="label">RTL</span>
										</router-link>
									</a-menu-item>
									<a-menu-item>
										<router-link to="/pages/charts">
											<span class="label">Charts</span>
										</router-link>
									</a-menu-item>
									<a-menu-item>
										<router-link to="/pages/alerts">
											<span class="label">Alerts</span>
										</router-link>
									</a-menu-item>
									<a-menu-item>
										<router-link to="/pages/notifications">
											<span class="label">Notifications</span>
										</router-link>
									</a-menu-item>
								</a-menu-item-group>
							</a-sub-menu>
							<a-sub-menu key="applications" style="padding: 0;">
								<span slot="title">
									<span class="icon">
										<a-icon type="appstore" theme="filled" class="m-0" />
									</span>
									<span class="label">Applications</span>
								</span>
								<a-menu-item-group>
									<a-menu-item>
										<router-link to="/applications/kanban">
											<span class="label">Kanban</span>
										</router-link>
									</a-menu-item>
									<a-menu-item>
										<router-link to="/applications/wizard">
											<span class="label">Wizard</span>
										</router-link>
									</a-menu-item>
									<a-menu-item>
										<router-link to="/applications/datatables">
											<span class="label">DataTables</span>
										</router-link>
									</a-menu-item>
									<a-menu-item>
										<router-link to="/applications/calendar">
											<span class="label">Calendar</span>
										</router-link>
									</a-menu-item>
								</a-menu-item-group>
							</a-sub-menu>
							<a-sub-menu key="ecommerce" style="padding: 0;">
								<span slot="title">
									<span class="icon">
										<a-icon type="dollar-circle" theme="filled" class="m-0" />
									</span>
									<span class="label">Ecommerce</span>
								</span>
								<a-menu-item-group>
									<a-sub-menu key="products" style="padding: 0;" title="Products">
										<a-menu-item-group>
											<a-menu-item>
												<router-link to="/ecommerce/products/new-product">
													<span class="label">New Product</span>
												</router-link>
											</a-menu-item>
											<a-menu-item>
												<router-link to="/ecommerce/products/edit-product">
													<span class="label">Edit Product</span>
												</router-link>
											</a-menu-item>
											<a-menu-item>
												<router-link to="/ecommerce/products/product-page">
													<span class="label">Product Page</span>
												</router-link>
											</a-menu-item>
										</a-menu-item-group>
									</a-sub-menu>
									<a-sub-menu key="orders" style="padding: 0;" title="Orders">
										<a-menu-item-group>
											<a-menu-item>
												<router-link to="/ecommerce/orders/orders-list">
													<span class="label">Order List</span>
												</router-link>
											</a-menu-item>
											<a-menu-item>
												<router-link to="/ecommerce/orders/orders-details">
													<span class="label">Order Details</span>
												</router-link>
											</a-menu-item>
										</a-menu-item-group>
									</a-sub-menu>
								</a-menu-item-group>
							</a-sub-menu>
							<a-sub-menu key="authentication" style="padding: 0;">
								<span slot="title">
									<span class="icon">
										<a-icon type="lock" theme="filled" class="m-0" />
									</span>
									<span class="label">Authentication</span>
								</span>
								<a-menu-item-group>
									<a-sub-menu key="sign-up" style="padding: 0;" title="Sign Up">
										<a-menu-item-group>
											<a-menu-item>
												<router-link to="/authentication/sign-up/basic">
													<span class="label">Basic</span>
												</router-link>
											</a-menu-item>
											<a-menu-item>
												<router-link to="/authentication/sign-up/cover">
													<span class="label">Cover</span>
												</router-link>
											</a-menu-item>
											<a-menu-item>
												<router-link to="/authentication/sign-up/illustration">
													<span class="label">Illustration</span>
												</router-link>
											</a-menu-item>
										</a-menu-item-group>
									</a-sub-menu>
								</a-menu-item-group>
							</a-sub-menu>
							<a-menu-item class="menu-item-header">
								<hr class="mt-5">
								Docs
							</a-menu-item>
							<a-sub-menu key="basic" style="padding: 0;">
								<span slot="title">
									<span class="icon">
										<a-icon type="read" theme="filled" class="m-0" />
									</span>
									<span class="label">Basic</span>
								</span>
								<a-menu-item-group>
									<a-menu-item>
										<router-link to="/docs/basic/links-to-the-Docs">
											<span class="label">links to the Docs</span>
										</router-link>
									</a-menu-item>
								</a-menu-item-group>
							</a-sub-menu>
							<a-sub-menu key="components" style="padding: 0;">
								<span slot="title">
									<span class="icon">
										<a-icon type="rocket" theme="filled" class="m-0" />
									</span>
									<span class="label">Components</span>
								</span>
								<a-menu-item-group>
									<a-menu-item>
										<router-link to="/docs/components/links-to-the-Docs">
											<span class="label">links to the Docs</span>
										</router-link>
									</a-menu-item>
								</a-menu-item-group>
							</a-sub-menu>
							<a-menu-item>
								<a href="https://github.com/creativetimofficial/ct-muse-vue-ant-design-dashboard-pro/blob/main/CHANGELOG.md" target="_blank">
									<span class="icon">
										<a-icon type="switcher" theme="filled" class="m-0" />
									</span>
									<span class="label">Changelog</span>
								</a>
							</a-menu-item>
							<a-menu-item>
								<a-button v-if="isAuthenticated" danger type="link" @click.prevent="logout">
									<span>Logout</span>
								</a-button>
							</a-menu-item>
						</a-menu>
						<!-- / Navigation Menu For Small Screens -->

					</a-collapse-panel>
				</a-collapse>
				<!-- / Collapsible Component For Navigation Menu For Small Screens -->

			</div>
			<!-- / Collapsible Navigation Menu For Small Screens -->

		</div>
	</a-layout-header>
	<!-- / Layout Header ( Navbar ) -->

</template>

<script>
    import { mapState } from 'vuex'

	export default ({
		data() {
			return {
			    app_name: process.env.VUE_APP_NAME,
				// Collapse navigation value.
				// Binded model property for "Collapsible Navigation Menu" collapsed status .
				collapseNav: 0,
				// Sidebar collapsed status.
				sidebarCollapsed: {
					type: Boolean,
					default: false,
				},
				
				// Main sidebar color.
				sidebarColor: {
					type: String,
					default: "primary",
				},
				
				// Main sidebar theme : light, white, dark.
				sidebarTheme: {
					type: String,
					default: "light",
				},
      			rootSubmenuKeys: ['dashboards', 'pages', 'applications', 'ecommerce', 'authentication', 'basic', 'components', 'changelog'],
				openKeys: null,
			}
		},
		mounted()
		{
		},
		methods: {
            goToProfile()
			{
			    if(this.isEmployee)
				{
                    this.$store.dispatch("COMMIT_SET_SELECTED_EMPLOYEE", this.auth_user)
                    this.$router.push({
                        name: "employeeHRView"
                    })
				}
			},
			logout()
			{
                this.$store.dispatch("COMMIT_LOGOUT")
			    this.$router.push({
					name: "login"
				})
			},
			isCurrentRoute(_name)
			{
			    return this.$route.name === _name
			},
			onOpenChange(openKeys)
			{
				this.openKeys = this.openKeys ? this.openKeys : this.$route.meta.sidebarMap ;
				
				const latestOpenKey = openKeys.find( key => this.openKeys.indexOf( key ) === -1) ;

				if ( this.rootSubmenuKeys.indexOf( latestOpenKey ) === -1 )
				{
					this.openKeys = openKeys;
				}
				else
				{
					this.openKeys = latestOpenKey ? [ latestOpenKey ] : [] ;
				}
			},
		},
		computed: {
            ...mapState({
                selected_organisation: state => state.organisation.selected,
                auth_user: state => state.auth.user,
                auth_selected_role: state => state.auth.selected_role,
                auth_roles: state => state.auth.roles,
                auth_type: state => state.auth.type,
                role_type: state => state.auth.role_type,
            }),
            hasAuthUser()
            {
                return this.$itemExists(this.auth_user)
            },
            hasSelectedOrganisation()
            {
                return this.$itemExists(this.selected_organisation)
            },
            hasAuthRoles()
            {
                return this.hasAuthUser && this.$itemListExists(this.auth_roles)
            },
            isAuthenticated()
            {
                if(this.hasAuthRoles && this.$itemExists(this.auth_selected_role))
                {
                    let _thisObj = this
                    let _arr_filter = this.auth_roles.filter((_item) => {
                        return _thisObj.auth_selected_role.alias === _item.alias
                    })
                    if(_thisObj.$itemListExists(_arr_filter))
                        return true
                }

                return false
            },
            currentRole() {
                if(this.isAuthenticated)
                    return this.auth_selected_role

                return null
            },
            hasCurrentRole()
            {
                return this.$itemExists(this.currentRole)
            },
            canActAsSystemAdmin()
            {
                return this.hasCurrentRole && ["system-admin", "super-admin"].indexOf(this.currentRole.alias) >= 0
            },
            canActAsSupermAdmin()
            {
                return this.hasCurrentRole && ["super-admin"].indexOf(this.currentRole.alias) >= 0
            },
			hasRoleType()
			{
			    return this.hasCurrentRole && this.$itemExists(this.currentRole.type) && this.$itemExists(this.currentRole.type.data)
			},
			canActAsOrganisationAdmin()
			{
				return this.canActAsSystemAdmin || (this.hasRoleType && this.currentRole.type.data.alias === "owner")
			},
            canActAsOrganisationManager()
            {
                return this.canActAsSystemAdmin || this.canActAsOrganisationAdmin || (this.hasRoleType && this.currentRole.type.data.alias === "manager")
            },
            isEmployee()
            {
                return this.auth_type === "employee"
            },
            isAdministrator()
            {
                return this.auth_type === "admin"
            }
		}
	})

</script>

<style lang="scss" scoped>

	.nav-link svg {
		margin-right: 5px;
		vertical-align: middle;
	}
	.nav-link span {
		vertical-align: middle;
	}
	.ant-menu-submenu-popup {
		width: 100%;
	}

</style>