/**
 * Created by fulle on 2023/02/10.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import CUSTOM_CONSTANTS from "@/constants";
import http from "@/http-common";

import auth from "./modules/authStore";
import account from "./modules/accountStore";
import certificate from "./modules/certificateStore";
import organisation from "./modules/organisationStore";
import category from "./modules/categoryStore";
import producttype from "./modules/producttypeStore";
import product from "./modules/productStore";
import order from "./modules/orderStore";
import user from "./modules/userStore";
import department from "./modules/hr/departmentStore";
import position from "./modules/hr/positionStore";
import employment from "./modules/hr/employmentStore";
import employee from "./modules/hr/employeeStore";
import document from "./modules/hr/documentStore";
import goal from "./modules/hr/goalStore";
import review from "./modules/hr/reviewStore";
import notice from "./modules/hr/noticeStore"
import leavetype from "./modules/hr/leavetypeStore"
import enUS from 'ant-design-vue/es/locale/en_US'


Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token : localStorage.getItem('token') | null,
        processing:1,
        messages:[],
        user: null,
        actionName:"",
        auth_key : null,
        trigger_tooltip:false,
        menu_links: [],
        page_header_html: null,
        access_roles: CUSTOM_CONSTANTS.access_roles,
        current_access_role: null,
        locale: enUS,
        languages: null,
        list: {
            languages: null
        }
    },
    mutations: {
        AUTH_SET_ACCESS_ROLE : (state,_value) =>
        {
            state.current_access_role = _value;
        },

        AUTH_REQUEST: (state) => {
            state.status = 'loading'
        },
        AUTH_SUCCESS: (state, token) => {
            state.status = "success";
            state.token = token;
            http.defaults.headers.common["Authorization"] = "Bearer " + token;
        },
        AUTH_LOGOUT: (state) => {
            state.status = "";
            state.login = null;
            state.token = null;
            state.processing = 0;
            state.messages = [];
            state.rule = {};
            state.user = {};
            state.auth_key = null;
            localStorage.removeItem("token");
            delete http.defaults.headers.common["Authorization"];
        },
        START_PROCESSING: (state) =>
        {
            state.processing++;
            console.log("Processing - Start: "+state.processing);
        },
        STOP_PROCESSING: (state) =>
        {
            if(state.processing > 0)
            {
                state.processing--;
            }
            console.log("Processing - Stop: "+state.processing);
        },
        SET_MESSAGES: (state, _messages) => {
            console.log(JSON.stringify(_messages));
            state.messages = _messages;
        },
        AUTH_SET_KEY: (state, _value) => {
            state.auth_key = _value;
        },
        SET_USER:(state,user)=>
        {
            state.user = user;
        },
        AUTH_SET_LOGIN:(state,login)=>
        {
            state.login = login;
        },
        SET_ACTION: (state, _value) =>
        {
            state.actionName = _value;
        },
        SET_TRIGGER_TOOLTIP(state, _value)
        {
            state.trigger_tooltip = _value;
        },
        SET_MENU_LINKS:(state,_value)=>
        {
            state.menu_links = _value;
        },
        SET_PAGE_HEADER_HTML: (state, _value) => {
            state.page_header_html = _value;
        },
        SET_LOCALE: (state, _value) => {
            state.locale = _value;
        },
        SET_LANGUAGES: (state, _value) => {
            state.languages = _value;
        },
        SET_LIST_ALL_LANGUAGE: (state, _value) => {
            state.list.languages = _value;
        },
    },
    actions: {
        COMMIT_SET_LOCALE:({commit},_value)=>
        {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
            {
                _sessionStorage.setItem('locale', JSON.stringify(_value))
            }
            commit('SET_LOCALE',_value);
        },
        COMMIT_SET_LANGUAGES:({commit},_value)=>
        {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
            {
                _sessionStorage.setItem('languages', JSON.stringify(_value))
            }
            commit('SET_LANGUAGES',_value);
        },
        COMMIT_REFRESH_LOCALE:({commit})=>
        {
            let _value = enUS
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
            {
                if(typeof _sessionStorage.getItem('locale') !== "undefined"&& _sessionStorage.getItem('locale') !== null && _sessionStorage.getItem('locale').length > 0)
                    _value = JSON.parse(_sessionStorage.getItem('locale'))
            }
            window.console.log()
            commit('SET_LOCALE',_value);
        },
        COMMIT_REFRESH_LANGUAGES:({commit})=>
        {
            let _value = enUS
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
            {
                if(typeof _sessionStorage.getItem('languages') !== "undefined"&& _sessionStorage.getItem('locale') !== null && _sessionStorage.getItem('locale').length > 0)
                    _value = JSON.parse(_sessionStorage.getItem('languages'))
            }
            window.console.log()
            commit('SET_LANGUAGES',_value);
        },
        COMMIT_SET_MENU_LINKS:({commit},_value)=>
        {
            commit('SET_MENU_LINKS',_value);
        },
        COMMIT_SET_PAGE_HEADER_HTML: ({ commit }, _value) => {
            commit("SET_PAGE_HEADER_HTML", _value);
        },

        COMMIT_SET_ACCESS_ROLE: ({commit}, _value) =>
        {
            commit('AUTH_SET_ACCESS_ROLE',_value);
        },
        AUTH_LOGOUT: ({commit}) => {
            return new Promise((resolve) => {
                commit('AUTH_LOGOUT');
                resolve();
            })
        },
        START_PROCESSING:({commit}) =>
        {
            commit('START_PROCESSING');
        },
        STOP_PROCESSING:({commit}) =>
        {
            commit('STOP_PROCESSING');
        },

        COMMIT_SET_ACTION:({commit},_value)=>
        {
            commit('SET_ACTION',_value);
        },

        COMMIT_SET_MESSAGES:({commit},messages)=>
        {
            console.log("SET MESSAGES TO "+JSON.stringify(messages));
            commit('SET_MESSAGES',messages);
        },

        SET_TRIGGER_TOOLTIP : ({commit}, _value) =>
        {
            commit("SET_TRIGGER_TOOLTIP",_value);
        },
        RESPONSE_PROCESS_AJAX_ERROR : ({commit}, _value) =>
        {
            window.console.log("Error* "+ JSON.stringify(_value));
            let _arrMsg = [];
            if(typeof _value.data !== "undefined" && _value.data !== null)
            {
                if(typeof _value.data.message !== "undefined" && _value.data.message !== null && _value.data.message.length > 0)
                {
                    _arrMsg.push(_value.data.message);
                }
                else if(typeof _value.data.errorMessage !== "undefined" && _value.data.errorMessage !== null && _value.data.errorMessage.length > 0)
                {
                    _arrMsg.push(_value.data.errorMessage);
                }
                else if(typeof _value.data.messages !== "undefined" && _value.data.messages !== null && _value.data.messages.length > 0)
                {
                    _arrMsg = _value.data.messages;
                }
                else
                {
                    _arrMsg.push("An error occurred, please try again later.");
                }

            }
            else if(typeof _value.message !== "undefined" && _value.message !== null)
            {
                _arrMsg.push(_value.message);
            }
            else if(typeof _value.errorMessage !== "undefined" && _value.errorMessage !== null && _value.errorMessage.length > 0)
            {
                _arrMsg.push(_value.errorMessage);
            }

            else
            {
                window.console.log("Unhandled-Error------");
                window.console.log(JSON.stringify(_value));
                _arrMsg.push("Unhandled Error - An error occurred, please try again later.");
            }
            commit("SET_MESSAGES", _arrMsg);
        },

        COMMIT_SET_LIST_ALL_LANGUAGE:({commit},_value)=>
        {
            commit('SET_LIST_ALL_LANGUAGE',_value);
        },
        REQUEST_LANGUAGE_LIST_SELECT: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
            return new Promise(resolve => {
                http({
                    url: '/language/select',
                    method: "POST",
                    timeout: 30000,
                    data: _formData
                })
                    .then(_response => {
                        window.console.log("Response LANGUSGE PAGINATED LIST: ", _response);
                        if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                        {
                            reject(_response)
                            return false
                        }
                        commit('SET_LIST_ALL_LANGUAGE', _response.data.data);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
    },
    getters: {
        hasAccessRole: state =>
            (typeof state.auth.roles !== "undefined" &&
            state.auth.roles !== null && state.auth.roles.length > 0 &&
            typeof state.auth.selected_role !== "undefined" &&
            state.auth.selected_role !== null &&
            state.auth.roles.indexOf(state.auth.selected_role) >= 0),

        isAuthenticated: (state,getters) =>
            (typeof state.auth.user !== "undefined" &&
            state.auth.user !== null &&
            getters.hasAccessRole),


        isSuperAdmin: (state,getters) =>
            (getters.isAuthenticated && parseInt(state.auth.selected_role.alias) === 'super-admin'),
        isSystemAdmin: (state,getters) =>
            (getters.isAuthenticated && parseInt(state.auth.selected_role.alias) === 'system-admin'),
        canActAsSuperAdmin: (state,getters) =>
            (getters.isSystemAdmin || getters.isSuperAdmin),
        canActAsSystemAdmin: (state,getters) =>
            (getters.isSystemAdmin),
    },
    modules: {
        auth, account, organisation, category, certificate, producttype, product, order, user,
        department, position, employment, employee, document, goal, review, notice, leavetype
    },
});
