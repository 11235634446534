/**
 * Created by fulle on 2023/03/01.
 */
import moment from "moment"

const isValidDate = (value) => {
    if(typeof value !== "undefined" && value !== null && value.length > 0)
        return moment(value).isValid()

    return true
}

const isNumeric = (_value) => {
    return !isNaN(parseFloat(_value)) && isFinite(_value);
}

const formatFileSize = (bytes, decimalPoints) => {
    if(!isNumeric(bytes)||bytes<0)
        return "Undefined"

    if(bytes == 0) return "0 Bytes"
    let k = 1000,
        dm = decimalPoints || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes)/Math.log(k))

    return parseFloat((bytes/Math.pow(k,i)).toFixed(dm)) + " "+ sizes[i]
}

export { isValidDate, isNumeric, formatFileSize }