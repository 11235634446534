import axios from "axios";

export default axios.create({
  /*TODO: Setup dev base url when ready*/
  /*Dev Url*/
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-gateway": process.env.VUE_APP_API_KEY,
  },
});
